import { useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import Lottie from 'react-lottie';
import YouTube from "react-youtube";
import SvgAdi from '../assets/images/adi.svg';
import PngAppStore from '../assets/images/appstore.png';
import SvgCar from '../assets/images/car.svg';
import PngPlayStore from '../assets/images/google-play-badge.png';
import SvgLgvPcv from '../assets/images/lgv-pcv.svg';
import PngLogo from '../assets/images/logo.png';
import SvgMotorcycle from '../assets/images/motorcycle.svg';
import SvgPlay from '../assets/images/play.svg';
import animationData from './animation/23150-blob-animation.json';
import './styles.scss';

export type AppType = 'car' | 'motorcycle' | 'lgv-pcv' | 'adi';

const TheoryTestDefault = () => {

    const [appType, setAppType] = useState<AppType>('car');
    const [showVideo, setShowVideo] = useState<Boolean>(false);

    const defaultLottieOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className="theory-test">

            <div className="logo-container">
                <img src={PngLogo} alt="Drivingo" className="logo" />
            </div>
            
            {showVideo &&
                <div>
                    <YouTube videoId="aaJH60Etc84" opts={{
                        height: '513',
                        width: '912',
                        playerVars: {
                            autoplay: 1,
                            rel: 0
                        },
                    }} />
                    <a href="javascript:;" onClick={() => setShowVideo(false)} className="close">X Close</a>
                </div>
            }

            {!showVideo &&
                <div className="message-container">
                    <div className="animation">
                        <Lottie options={defaultLottieOptions} />
                    </div>
                    <p className="message">
                        Let’s get you ready for the theory test 2024.
                    </p>
                    <MobileView  className="watch">
                        <div onClick={() => window.open('https://youtu.be/aaJH60Etc84')} className="watch-wrapper">
                            <img alt="" src={SvgPlay} className="watch-icon" />
                            <span>
                                Watch the video
                            </span>
                        </div>
                    </MobileView>
                    <BrowserView className="watch">
                        <div onClick={() => setShowVideo(true)} className="watch-wrapper">
                            <img alt="" src={SvgPlay} className="watch-icon" />
                            <span>
                                Watch the video
                            </span>
                        </div>
                    </BrowserView>
                </div>
            }

            <div className="app-types">
                <div className={getClassName(appType, 'car')} onClick={() => setAppType('car')}>
                    <img src={SvgCar} alt="car" />
                    <span>CAR</span>
                </div>
                <div className={getClassName(appType, 'motorcycle')} onClick={() => setAppType('motorcycle')}>
                    <img src={SvgMotorcycle} alt="motorcycle" />
                    <span>MOTORCYCLE</span>
                </div>
                <div className={getClassName(appType, 'lgv-pcv')} onClick={() => setAppType('lgv-pcv')}>
                    <img src={SvgLgvPcv} alt="lgv-pcv" />
                    <span>LGV&PCV</span>
                </div>
                <div className={getClassName(appType, 'adi')} onClick={() => setAppType('adi')}>
                    <img src={SvgAdi} alt="adi" />
                    <span>ADI</span>
                </div>
            </div>

            {appType === 'car' &&
                <div className="app-details fade-in app-car">
                    <div className="detail-left">
                        <div className="detail-title">CAR</div>
                        <div className="detail-title-sub">Theory Test questions</div>
                        <div className="detail-small-title">LICENSED FROM DVSA</div>
                        <div className="detail-text">We have everything you need to pass your driving test first time. Revise translated questions in your language. Practise mock tests and hazard perception videos.</div>
                        <div className="detail-links">
                            <a href="https://apps.apple.com/us/app/drivingo-theory-test/id1556971244" className="store" target="_blank" rel="noreferrer">
                                <img alt="Download Drivingo" src={PngAppStore} className="store-google-img" />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=uk.co.drivingo.theorytest" className="store" target="_blank" rel="noreferrer">
                                <img alt="Download Drivingo" src={PngPlayStore} className="store-google-img" />
                            </a>
                        </div>
                    </div>
                    <div className="detail-right"></div>
                </div>
            }

            {appType !== 'car' &&
                <div className="app-details coming-soon">
                    coming soon...
                </div>
            }

            <div className="footer">
                <a target="_blank" rel="noreferrer" href="/theory-test/app-web-view/privacy-policy">Privacy policy</a>
                <a target="_blank" rel="noreferrer" href="/theory-test/app-web-view/terms-of-service">Terms of service</a>
                <a target="_blank" rel="noreferrer" href="mailto:theory-test@drivingo.co.uk">Contact</a>
            </div>

            <div className="foot-note">
                Crown copyright material has been reproduced by permission of the Driver and Vehicle Standards Agency, which does not accept any responsibility for the accuracy of the reproduction.
            </div>

        </div>
    );


}

function getClassName(stateAppType: AppType, elementAppType: AppType) {
    return stateAppType === elementAppType ? 'app-type app-type--' + elementAppType + ' app-type-active' : 'app-type app-type--' + elementAppType;
}

export default TheoryTestDefault;
