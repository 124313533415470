import React from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import TheoryTestAppPrivacyPolicy from './theory-test/app-web-view/PrivacyPolicy';
import TheoryTestAppTermsOfService from './theory-test/app-web-view/TermsOfService';
import TheoryTestDefault from './theory-test/Default';

function App() {
  return (
    <Router>
      <Routes>``
        <Route path="/" element={<Navigate to={'theory-test'} replace={true} />} />
        <Route path="/theory-test" element={<TheoryTestDefault />} />
        <Route path="/theory-test/app-web-view/privacy-policy" element={<TheoryTestAppPrivacyPolicy />} />
        <Route path="/theory-test/app-web-view/terms-of-service" element={<TheoryTestAppTermsOfService />} />
      </Routes>
    </Router>
  );
}

export default App;